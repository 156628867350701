<template>
  <div v-if="exceedsAchTransactionLimit" class="label-container">
    <div class="label-icon-container">
      <b-img src="/images/payments/message-warning-triangle.svg" />
    </div>
    <div class="label-text-container">
      <span class="label-text">ACH payment is not available for orders over $8,000. <b>Select a credit/debit card to continue with your payment.</b></span>
    </div>
  </div>
</template>

<script>

import { paymentMethodsMixin } from '@/mixins/paymentMethodsMixin'

export default {
  name: 'AchLimitLabel',
  mixins: [paymentMethodsMixin],
  data() {
    return {}
  },
}
</script>


<style scoped lang="scss">

.label-container {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  background: #FFF;
  border: 1px solid #FFC107;
  border-radius: 0.25rem;
  width: 100%;
  margin-bottom: 0.5rem;

  .label-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFC107;
    padding: 0.5rem;
    height: 100%;
    flex-shrink: 0;
  }

  .label-text-container {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;

    .label-text {
      padding-left: 0.5rem;
      color: #363636;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
</style>

