var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isExpired || _vm.isExpiredSoon
    ? _c(
        "div",
        {
          staticClass: "d-inline-flex label-container",
          class: _vm.isExpired
            ? "bg-expired border-expired expired-container-width"
            : "bg-expired-soon border-expired-soon expired-soon-container-width",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-center icon-container",
              class: _vm.isExpired
                ? "bg-expired-border"
                : "bg-expired-soon-border",
            },
            [
              _c("expired-card-icon", {
                staticClass: "icon",
                attrs: { "is-expired": _vm.isExpired },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "d-flex align-items-center justify-content-center w-100",
            },
            [
              _c("span", { staticClass: "label" }, [
                _vm._v(_vm._s(_vm.isExpired ? "Expired" : "Exp. Soon")),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }