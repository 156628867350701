export const unknown = 'unknown'
export const mastercard = 'mastercard'
export const visa = 'visa'
export const amex = 'amex'
export const discover = 'discover'
export const americanexpress = 'american express'

export const type = {
  unknown,
  mastercard,
  visa,
  amex,
  discover,
}

export const getType = number => {
  var input = String(number).replace(/\s/g, '')
  var containsNonNumeric = /[a-z\D]/gi

  if (input.length === 0 || input.search(containsNonNumeric) > -1) {
    return unknown
  }

  if (number === null || number === undefined || number === false) {
    return unknown
  }

  if (/^5[0-5]/.test(input)) {
    //Mastercard - mastercards are prefixed by 50-55
    return mastercard
  } else if (/^4/.test(input)) {
    //Visa - visas are prefixed by 4
    return visa
  } else if (/^3[147]/.test(input)) {
    //Amex - american express is prefixed by 3 and either 1,4, or 7
    return amex
  } else if (/^60[11-65]/.test(input)) {
    return discover
  }

  return unknown
}

export const getCardLogo = brand => {
  if (brand !== null && typeof(brand) !== 'undefined') {
    const normalizedBrand = brand.toLocaleLowerCase()
    const brandLogos = [americanexpress, discover, mastercard, visa]
    if (brandLogos.includes(normalizedBrand)) {
      return `/images/creditCards/${normalizedBrand}.svg`
    } else {
      return `/images/creditCards/default.svg`
    }
  } else {
    return `/images/creditCards/default.svg`
  }
}

export const getDarkCardLogo = brand => {
  if (brand !== null && typeof(brand) !== 'undefined') {
    const normalizedBrand = brand.toLocaleLowerCase()
    const brandLogos = [americanexpress, discover, mastercard, visa]
    if (brandLogos.includes(normalizedBrand)) {
      return `/images/darkCreditCards/${normalizedBrand}.svg`
    } else {
      return `/images/darkCreditCards/default.svg`
    }
  } else {
    return `/images/darkCreditCards/default.svg`
  }
}
