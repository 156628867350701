<template>
  <div v-if="isExpired || isExpiredSoon"
    class="d-inline-flex label-container"
    :class="isExpired ? 'bg-expired border-expired expired-container-width' :
    'bg-expired-soon border-expired-soon expired-soon-container-width'">
    <div class="d-flex align-items-center justify-content-center icon-container"
      :class="isExpired ? 'bg-expired-border' : 'bg-expired-soon-border'"
    >
      <expired-card-icon class="icon" :is-expired="isExpired" />
    </div>
    <div class="d-flex align-items-center justify-content-center w-100">
      <span class="label">{{ isExpired ? 'Expired' : 'Exp. Soon' }}</span>
    </div>
  </div>
</template>
<script>

import ExpiredCardIcon from '@/components/Payments/ExpiredCards/ExpiredCardIcon'
export default {
  name: 'ExpiredCardLabel',
  components: { ExpiredCardIcon },
  props: {
    card: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      expiredSrc: '/images/payments/message-alert-triangle.svg',
      expiredSoonSrc: '/images/payments/message-warning-triangle.svg',
    }
  },
  computed: {
    isExpired() {
      return this.card?.expired === true
    },
    isExpiredSoon() {
      return this.card?.expiring_soon === true
    },
  },
}
</script>

<style scoped lang="scss">
  .label-container {
    border-radius: 4px;
    height: 20px !important;
  }
  .icon-container {
    width: 24px !important;
  }
  .expired-container-width { width: 75px !important; }
  .bg-expired { background-color: #F8D7DA; }
  .bg-expired-soon { background-color: #FFE8A4; }
  .bg-expired-border { background-color: #DC3644; }
  .bg-expired-soon-border { background-color: #FFC109; }
  .border-expired { border: 2px solid #DC3644; }
  .border-expired-soon { border: 2px solid #FFC109; }
  .label {
    font-size: 10px;
    font-weight: bold;
    padding: 0.25rem;
  }
  .icon {
    padding-left: 2px;
    padding-right: 2px;
    width: 90%;
  }
</style>
