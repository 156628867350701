var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.exceedsAchTransactionLimit
    ? _c("div", { staticClass: "label-container" }, [
        _c(
          "div",
          { staticClass: "label-icon-container" },
          [
            _c("b-img", {
              attrs: { src: "/images/payments/message-warning-triangle.svg" },
            }),
          ],
          1
        ),
        _vm._m(0),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "label-text-container" }, [
      _c("span", { staticClass: "label-text" }, [
        _vm._v("ACH payment is not available for orders over $8,000. "),
        _c("b", [
          _vm._v("Select a credit/debit card to continue with your payment."),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }